import { http } from '../api';
import { Filters, PaginateResponse } from '../model';
import { Bed } from '../model/Bed';
import { Room, RoomType } from '../model/Room';

const controller = '/bed';


export async function getPaginate(): Promise<Room[]> {
  const { data } = await http.get<PaginateResponse<Bed>>(`${controller}/?situacao_leito=Ativo&page=1&limit=10000`);
  return data.data.map(bed => ({
    id: bed.cd_leito.toString(),
    description: bed.ds_leito,
    roomNumber: bed.ds_leito,
    ramal: bed.nr_ramal ?? '',
    accomodations: [],
    type: RoomType.Luxury,
    floor: bed.unidade_internacao,
    enabled: true,
  }));
}

export async function getByName(filters: Filters): Promise<Room> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['ds_leito'] = filters?.name;
  }
  const { data } = await http.get<Room>(controller, {
    params,
  });

  return data;
}

// TODO: Alterar para buscar por quarto
export async function getPatientInfoByBed(dsLeito: string): Promise<any> {
  const { data } = await http.get<any>(`${controller}/search/byPatient?ds_leito=${dsLeito}`);
  return data;
}
