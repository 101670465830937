<template>
    <div class="icon-container">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 2L2 20M2 2L20 20" stroke="#292E36" stroke-width="2.25" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </div>
</template>

<style scoped>
.icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.close-icon {
    transition: transform 0.3s ease;
}

.icon-container:hover {
    background-color: #e0e0e0;
    border-radius: 100%;
}
</style>