export const priorityMapping: { [key: number]: string } = {
    1: "Baixa",
    2: "Média",
    3: "Alta",
};

export function getPriorityClass(priority: string) {
    switch (priority) {
        case 'Alta':
            return 'priority high';
        case 'Média':
            return 'priority medium';
        case 'Baixa':
            return 'priority low';
        default:
            return '';
    }
}