import { statusMapping } from '@/core/enum/service';
import { ExpandedServiceAgent } from '@/core/model/Services';
import { getServicesAgent, interruptService } from '@/core/service/ServiceService';
import { reactive } from 'vue';

const state = reactive<{ servicesAgent: ExpandedServiceAgent[]; filteredServicesAgent: ExpandedServiceAgent[] }>({
    servicesAgent: [],
    filteredServicesAgent: [],
});


const actions = {
    async GetByAgent(agentId: string) {
        const data = await getServicesAgent(agentId);
        state.servicesAgent = data.map(service => ({
            ...service,
            expanded: false,
        }));

        getters.updateFilteredServices(0);
    },
    async InterruptService(id: string, reason: string) {
        const data = await interruptService(id, reason);
    }
};

const getters = {
    toggleExpanded(index: number) {
        if (state.servicesAgent && state.servicesAgent[index]) {
            state.servicesAgent[index].expanded = !state.servicesAgent[index].expanded;
        }
    },

    updateFilteredServices(selectedTab: number) {
        if (selectedTab === 0) {
            state.filteredServicesAgent = [...state.servicesAgent];
            return;
        }

        const statusToCheck = statusMapping[selectedTab];

        state.filteredServicesAgent = state.servicesAgent.filter(service => {
            if (statusToCheck === "Finalizado") {
                return service.status === "Finalizado" || service.status === "Arquivado";
            }

            return service.status === statusToCheck;
        });
    },


};

export { state, actions, getters };
