export function verifyPermission(permissionsALlow: Array<string>): boolean{
    const userPermisisons = getUserPermisisons()
    return permissionsALlow.some((value:any) =>  userPermisisons.includes(value))
}

export function getUserPermisisons(): Array<string>{
    const user = JSON.parse(localStorage.getItem('usuario') || '');
    
    if (user && Array.isArray(user.roles)) {
        return user.roles.map((role: any) => role.description);
    }
    return [];
}