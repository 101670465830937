<template>
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/bold/arrow-down">
            <g id="arrow-down">
                <path id="Vector"
                    d="M13.4202 0.180176H7.19024H1.58024C0.620237 0.180176 0.140237 1.34018 0.820237 2.02018L6.00024 7.20018C6.83024 8.03018 8.18024 8.03018 9.01024 7.20018L10.9802 5.23018L14.1902 2.02018C14.8602 1.34018 14.3802 0.180176 13.4202 0.180176Z"
                    fill="#005E59" />
            </g>
        </g>
    </svg>

</template>