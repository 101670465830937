import { refreshAllData } from '@/store';
import { http } from '../api';
import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '../model';
import { CreateActivityRequest, Activity } from '../model/Activity';

const controller = 'activity';

export async function getByCategory(categoryId: string): Promise<Activity[]> {
  const { data } = await http.get<Activity[]>(`${controller}/category/${categoryId}`);
  return data;
}

export async function getPaginate(paginate?: PaginateRequest,filters?: Filters): Promise<PaginateResponse<Activity>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Activity>>(`${controller}?limit=${paginate?.limit ? paginate.limit : 99999}&page=${paginate?.page ? paginate.page : 1}&showDisabled=true`, {
    params,
  });

  return { page: data.page, total: data.total, data: data.data, totalPages: data.totalPages, limit: data.limit };
}

export async function getBy(id: string): Promise<Activity> {
  const { data } = await http.get<Activity>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<Activity>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<Activity>(`${controller}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  await refreshAllData();
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

export async function enable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  await refreshAllData();
  return data;
}

export async function disable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  await refreshAllData();
  return data;
}

export async function getSelectIconList(): Promise<OptionSelect[]> {
  const data = [
    { text: 'Cama de Hospital', value: 'mdi-bed' },
    { text: 'Prontuário Médico', value: 'mdi-clipboard-pulse' },
    { text: 'Recursos Humanos', value: 'mdi-human-handsup' },
    { text: 'Finanças', value: 'mdi-currency-usd' },
    { text: 'Paciente', value: 'mdi-account-heart' },
    { text: 'Agendamento', value: 'mdi-calendar-clock' },
    { text: 'Laboratório', value: 'mdi-flask' },
    { text: 'Cirurgia', value: 'mdi-human-baby-changing-table' },
    { text: 'Ambulância', value: 'mdi-ambulance' },
    { text: 'Emergência', value: 'mdi-alert' },
    { text: 'Raio-X', value: 'mdi-radiology-box-outline' },
    { text: 'Seringa', value: 'mdi-needle' },
    { text: 'Médico', value: 'mdi-stethoscope' },
    { text: 'Kit Médico', value: 'mdi-hospital-box' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Psicologia', value: 'mdi-head-snowflake' },
    { text: 'Enfermeiro', value: 'mdi-doctor' },
    { text: 'Negócios', value: 'mdi-handshake' },
    { text: 'Coração', value: 'mdi-heart-pulse' },
    { text: 'Saúde & Bem Estar', value: 'mdi-heart-plus' },
    { text: 'Cuidados com o Paciente', value: 'mdi-mother-heart' },
    { text: 'Diagnóstico', value: 'mdi-puzzle-heart' },
    { text: 'Ressonância', value: 'mdi-radioactive' },
    { text: 'Pediatria', value: 'mdi-car-child-seat' },
    { text: 'Transporte de Paciente', value: 'mdi-transit-transfer' },
    { text: 'Acessibilidade', value: 'mdi-wheelchair-accessibility' },
    { text: 'Pílula', value: 'mdi-pill' },
    { text: 'Medicação', value: 'mdi-medical-bag' },
    { text: 'Paciente Aguardando', value: 'mdi-account-clock' },
    { text: 'Higiene Pessoal', value: 'mdi-hand-wash' },
    { text: 'Ajuda', value: 'mdi-hand-heart' },
    { text: 'Higiene', value: 'mdi-lotion-plus' },
    { text: 'Conserto', value: 'mdi-hammer-wrench' },
    { text: 'Curativo', value: 'mdi-bandage' },
    { text: 'Manutenção', value: 'mdi-wrench' },
    { text: 'Dentista', value: 'mdi-tooth' },
    { text: 'Escova/Pasta de Dente', value: 'mdi-toothbrush-paste' },
    { text: 'Pulmão', value: 'mdi-lungs' },
    { text: 'Alimentação', value: 'mdi-silverware-fork-knife' },
    { text: 'Localização', value: 'mdi-map-marker-plus' },
    { text: 'Casa', value: 'mdi-home-plus' },
    { text: 'Sangue', value: 'mdi-blood-bag' },
    { text: 'Idoso', value: 'mdi-human-cane' },
    { text: 'Localização Hospitalar', value: 'mdi-hospital-marker' },
    { text: 'Medicação Intravenosa', value: 'mdi-iv-bag' },
    { text: 'Perigo', value: 'mdi-skull-scan-outline' },
    { text: 'Vírus', value: 'mdi-virus-outline' },
    { text: 'Exame', value: 'mdi-diabetes' },
    { text: 'Termômetro', value: 'mdi-thermometer-plus' },
    { text: 'Genética', value: 'mdi-dna' },
    { text: 'Máscara', value: 'mdi-face-mask-outline' },
    { text: 'Microscópio', value: 'mdi-microscope' },
    { text: 'Helicóptero', value: 'mdi-helicopter' },
    { text: 'Pessoa', value: 'mdi-walk' },
    { text: 'Receita', value: 'mdi-file-document-edit' },
    { text: 'Documento', value: 'mdi-file-document-multiple-outline' },
    { text: 'Telefone', value: 'mdi-phone-dial' },
    { text: 'Maça', value: 'mdi-food-apple' },
    { text: 'Frango', value: 'mdi-food-turkey' },
    { text: 'Água', value: 'mdi-cup-water' },
    { text: 'Celular', value: 'mdi-cellphone-play' },
    { text: 'Café', value: 'mdi-coffee' },
    { text: 'Balança', value: 'mdi-scale' },
    { text: 'Cama Dupla', value: 'mdi-bed-double-outline' },
    { text: 'Cama King', value: 'mdi-bed-king-outline' },
    { text: 'Cama com Alarme', value: 'mdi-bed-clock' },
    { text: 'Pá', value: 'mdi-shovel' },
    { text: 'Tulipa', value: 'mdi-flower-tulip' },
    { text: 'Spa', value: 'mdi-spa-outline' },
    { text: 'Meditação', value: 'mdi-meditation' },
    { text: 'Basquete', value: 'mdi-basketball' },
    { text: 'Baseball', value: 'mdi-baseball-bat' },
    { text: 'Corrida', value: 'mdi-run-fast' },
    { text: 'Halterofilismo', value: 'mdi-weight-lifter' },
    { text: 'Ginástica', value: 'mdi-gymnastics' },
    { text: 'Arco e Flecha', value: 'mdi-bow-arrow' },
    { text: 'Halter', value: 'mdi-dumbbell' },
    { text: 'Comida', value: 'mdi-food-outline' },
    { text: 'Golfe', value: 'mdi-golf-tee' },
    { text: 'Chapéu de Chef', value: 'mdi-chef-hat' },
    { text: 'Sorvete', value: 'mdi-ice-cream' },
    { text: 'Maçã', value: 'mdi-food-apple-outline' },
    { text: 'Frango', value: 'mdi-food-turkey' },
    { text: 'Cafeteira', value: 'mdi-coffee-maker' },
    { text: 'Massa', value: 'mdi-pasta' },
    { text: 'Baguete', value: 'mdi-baguette' },
    { text: 'Bolo', value: 'mdi-cake-variant-outline' },
    { text: 'Vinho', value: 'mdi-glass-wine' },
    { text: 'Melancia', value: 'mdi-fruit-watermelon' },
    { text: 'Frutas Cítricas', value: 'mdi-fruit-citrus' },
    { text: 'Cerejas', value: 'mdi-fruit-cherries' },
    { text: 'Uvas', value: 'mdi-fruit-grapes-outline' },
    { text: 'Pera', value: 'mdi-fruit-pear' },
    { text: 'Abacaxi', value: 'mdi-fruit-pineapple' },
    { text: 'Batata Frita', value: 'mdi-french-fries' },
    { text: 'Coxa de Frango', value: 'mdi-food-drumstick-outline' },
    { text: 'Ovo Frito', value: 'mdi-egg-fried' },
    { text: 'Broto', value: 'mdi-sprout-outline' },
    { text: 'Bebida Alcoólica', value: 'mdi-liquor' },
    { text: 'Fogão', value: 'mdi-stove' },
    { text: 'Grelha', value: 'mdi-grill-outline' },
    { text: 'Folha', value: 'mdi-leaf' },
    { text: 'Coquetel', value: 'mdi-glass-cocktail' },
    { text: 'Caneca', value: 'mdi-glass-mug-variant' },
    { text: 'Cerveja', value: 'mdi-beer-outline' },
    { text: 'Secador de Cabelo', value: 'mdi-hair-dryer-outline' },
    { text: 'Aspirador', value: 'mdi-vacuum-outline' },
    { text: 'Spray', value: 'mdi-spray-bottle' },
    { text: 'Talheres Limpos', value: 'mdi-silverware-clean' },
    { text: 'Urso de Pelúcia', value: 'mdi-teddy-bear' },
    { text: 'Televisão', value: 'mdi-television' },
    { text: 'Piscina', value: 'mdi-pool' },
    { text: 'Termômetro de Piscina', value: 'mdi-pool-thermometer' },
    { text: 'Wi-Fi', value: 'mdi-wifi' },
    { text: 'Carro Lateral', value: 'mdi-car-side' },
    { text: 'Carro', value: 'mdi-car' },
    { text: 'Banheira', value: 'mdi-bathtub-outline' },
    { text: 'Chuveiro', value: 'mdi-shower' },
    { text: 'Papel Higiênico', value: 'mdi-paper-roll-outline' },
    { text: 'Cabeça de Chuveiro', value: 'mdi-shower-head' },
    { text: 'Vaso Sanitário', value: 'mdi-toilet' },
    { text: 'Pata', value: 'mdi-paw' },
    { text: 'Berço', value: 'mdi-cradle-outline' },
    { text: 'Mamadeira', value: 'mdi-baby-bottle-outline' },
    { text: 'Carrinho de Bebê', value: 'mdi-baby-carriage' },
    { text: 'Música', value: 'mdi-music' },
    { text: 'Nota Musical', value: 'mdi-music-note' },
    { text: 'Sino', value: 'mdi-bell-outline' },
    { text: 'Violão', value: 'mdi-guitar-acoustic' },
    { text: 'Microfone', value: 'mdi-microphone-variant' },
    { text: 'Cadeira de Rodas', value: 'mdi-wheelchair' },
    { text: 'Caixa de Hospital', value: 'mdi-hospital-box-outline' },
    { text: 'Escudo com Cadeado', value: 'mdi-shield-lock-outline' },
    { text: 'Maleta', value: 'mdi-briefcase-outline' },
    { text: 'Planeta Terra', value: 'mdi-earth' },
    { text: 'Mulher', value: 'mdi-human-female' },
    { text: 'Homem', value: 'mdi-human-male' },
    { text: 'Cachimbo', value: 'mdi-smoking-pipe' },
    { text: 'Proibido Fumar', value: 'mdi-smoking-off' },
    { text: 'Fumar', value: 'mdi-smoking' },
    { text: 'Controle de Videogame', value: 'mdi-gamepad-variant-outline' },
    { text: 'Nadar', value: 'mdi-swim' },
    { text: 'Ferro de Passar', value: 'mdi-iron-outline' },
    { text: 'Cabide', value: 'mdi-hanger' },
    { text: 'Gravata', value: 'mdi-tie' },
    { text: 'Geladeira', value: 'mdi-fridge-outline' },
    { text: 'Gato', value: 'mdi-cat' },
    { text: 'Cachorro', value: 'mdi-dog' },
    { text: 'Cachorro Lateral', value: 'mdi-dog-side' },
    { text: 'Marcador de Mapa', value: 'mdi-map-marker' },
    { text: 'Busca de Mapa', value: 'mdi-map-search-outline' },
    { text: 'Chave', value: 'mdi-key-variant' },
    { text: 'Estante de Livros', value: 'mdi-library-shelves' },
    { text: 'Prateleira', value: 'mdi-bookshelf' },
    { text: 'Livro Aberto', value: 'mdi-book-open-page-variant-outline' },
    { text: 'Sofá', value: 'mdi-sofa-outline' },
    { text: 'Sofá Individual', value: 'mdi-sofa-single-outline' },
    { text: 'Banheira de Hidromassagem', value: 'mdi-hot-tub' },
    { text: 'Liquidificador', value: 'mdi-blender-outline' },
    { text: 'Guarda-Sol de Praia', value: 'mdi-umbrella-beach-outline' },
    { text: 'Banco', value: 'mdi-bench-back' },
    { text: 'Cinema', value: 'mdi-movie-open-outline' },
    { text: 'Contas', value: 'mdi-account-multiple' }
];
  return data;
}

function validateAndAppendAll(formData: FormData, payload: CreateActivityRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (item instanceof File) {
              formData.append(`${key}`, item);
            } else {
              formData.append(`${key}`, String(item));
            }
          });
        } else {
          formData.append(key, String(value));
        }
      }
    }
  }
}

