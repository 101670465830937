<template>
    <svg :width="width" :height="height" fill="none" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
        <path :d="path" :stroke="fillColor" :stroke-width="strokeWidth" />
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 44
        },
        height: {
            type: Number,
            default: 44
        },
        fillColor: {
            type: String,
            default: 'white'
        },
        iconSize: {
            type: String,
            default: 'large',
        }
    },
    computed: {
        viewBox() {
            return this.iconSize === 'small' ? '0 0 16 16' : '0 0 44 44';
        },
        strokeWidth() {
            return this.iconSize === 'small' ? 2 : 4;
        },
        path() {
            return this.iconSize === 'small'
                ? 'M12.4441 12.4441L3.55523 3.55523M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z'
                : 'M35.3333 35.3333L8.66667 8.66667M42 22C42 33.0457 33.0457 42 22 42C10.9543 42 2 33.0457 2 22C2 10.9543 10.9543 2 22 2C33.0457 2 42 10.9543 42 22Z';
        }
    }
}
</script>