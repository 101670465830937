import { refreshAllData } from '@/store';
import { http } from '../api';
import { Filters, PaginateResponse } from '../model';
import { CreateCategoryRequest, Category } from '../model/Category';

const controller = 'category';

export async function getPaginate(filters?: Filters): Promise<PaginateResponse<Category>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Category> | any>(`${controller}/`, {
    params,
  });

  return  { page: data.page, total: data.total ? data.total : data.length , data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };
}

export async function getBy(id: string): Promise<Category> {
  const { data } = await http.get<Category>(`${controller}/${id}`);
  return data;
}

export async function getByDepartment(departmentId: string): Promise<Category[]> {
  const { data } = await http.get<Category[]>(`${controller}?department=${departmentId}`);
  return data;
}

export async function create(payload: CreateCategoryRequest): Promise<Category> {
  const { data } = await http.post<Category>(`${controller}/`, payload);
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateCategoryRequest): Promise<Category> {
  const { data } = await http.patch<Category>(`${controller}/${id}`, payload);
  await refreshAllData();
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

export async function enable(id: string): Promise<Category> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  await refreshAllData();
  return data;
}

export async function disable(id: string): Promise<Category> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  await refreshAllData();
  return data;
}