import { http } from '../api';
import qs from 'qs';
import { ServiceAgent, ServiceAgentResponse } from '../model/Services';

const controller = 'scservice';

type ServiceCreate = {
  activityId: string,
  quantity: number,
  operationId: number,
  location: string,
  priority: number,
  client: any,
  description: string
  triggerActivities?: Array<string>
}

export type ServicePendingResponse = {
  id: string;
  name: string;
  services: ServicePending[];
};

export type ServicePending = {
  id: string;
  activity: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  quantity: number;
  location: string;
  priority: number;
  client?: string;
  description: string;
  slaStatus: string;
  startTimestamp?: Date;
  endTimestamp?: Date;
  interruptTimestamp?: Date;
  cancelTimestamp?: Date;
  sla?: {
    toEnd: number;
  };
  cancelReason?: string;
  interruptReason?: string;
  agent?: {
    name: string;
    job?: string;
    photo?: string;
  };
}

export interface IRelatoryFilters {
  limit?: number;
  page?: number;
  start_date?: string;
  end_date?: string;
  location?: string;
  slaStatusExceed?: string;
  categoryId?: string;
  activityId?: string;
  departmentId?: string;
  doc?: string;
  rated?: boolean;
  requestingAgent?: string;
  alocatedAgent?: string;
  badReviews?: boolean;
  rate?: number;
  slaStatus?: string;
}

export type ReportResponse = {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  data: Report[];
};

export type Report = {
  startDate: string;
  location?: string;
  slaExceeded?: string;
  department?: string;
  category?: string;
  activity?: string;
  status?: string;
  alocatedAgent?: string;
  client?: string;
  quantity?: number;
  totalDuration?: string;
  enabled: boolean;
};

export type Relatory = {
  id: string;
  withDeleted: boolean;
  requestingAgent: string;
  attendanceId: string;
  dt_nascimento: string;
  nm_paciente: string;
  ds_leito: string;
  cd_leito: string;
  unidade_internacao: string;
  bed: string;
  activityId: string;
  categoryId: string;
  requestTimestamp: string;
  startTimestamp: Date;
  approveTimestamp: Date;
  rejectTimestamp: Date;
  acceptTimestamp: Date;
  endTimestamp: string;
  archiveTimestamp: Date;
  cancelTimestamp: Date;
  slaAcceptTimeExceded: string;
  slaStartTimeExceded: string;
  slaEndTimeExceded: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  rating: number | number[];
  companyId: string;
  slaStatus: string;
  badReviews?: boolean;
};
export async function create(service: ServiceCreate): Promise<void> {
  await http.post(`${controller}`, service);
}

export async function getRelatory(payload: IRelatoryFilters): Promise<ReportResponse> {

  console.log(payload);
  const params = qs.stringify(
    {
      ...payload,
      order: 'DESC',
      withDeleted: true,
    },
    { indices: false, skipNulls: true }
  );

  const { data } = await http.get<ReportResponse>(`${controller}/getReport?${params}`);

  console.log(data);

  const items = data.data.map((d) => {
    return {
      ...d,
      slaExceeded: getSlaText(d.slaExceeded ?? ''),
    };
  });

  return {
    ...data,
    data: items,
  };
}

function getSlaText(input: string) {
  switch (input) {
    case 'exceded':
      return 'Excedido';
    case 'normal':
      return 'Normal';
    case 'warning':
      return 'Quase Excedido';
    default:
      return '';
  }
}

export async function getServicesPending(departmentIds?: string[], hoursAgo?: number, totalItens?: number): Promise<ServicePendingResponse[]> {
  const params = new URLSearchParams();

  if (departmentIds) params.append('departments', departmentIds.join(','));
  if (hoursAgo) params.append('hoursAgo', hoursAgo.toString());
  if (totalItens) params.append('totalItens', totalItens.toString());

  const { data } = await http.get<ServicePendingResponse[]>(`${controller}/recent?${params.toString()}`);
  return data;
}

export async function getServicesAgent(agentId: string): Promise<ServiceAgent[]> {

  try {
    const { data } = await http.get<ServiceAgentResponse>(`${controller}/agent/${agentId}?page=1&limit=20`);

    return data.data.map(service => ({
      id: service.id,
      startDate: new Date(service.startDate),
      endDate: service.endDate ? new Date(service.endDate) : null,
      interruptTimestamp: service.interruptTimestamp ? new Date(service.interruptTimestamp) : undefined,
      cancelTimestamp: service.cancelTimestamp ? new Date(service.cancelTimestamp) : undefined,
      sla: service.sla,
      cancelReason: service.cancelReason,
      interruptReason: service.interruptReason,
      unity: service.unity,
      bed: service.bed,
      slaExceeded: service.slaExceeded,
      department: service.department,
      category: service.category,
      activity: service.activity,
      status: service.status,
      attendance: service.attendance,
      origin: service.origin,
      description: service.description,
      quantity: service.quantity,
      priority: service.priority ?? 1,
    }));
  } catch (error) {
    console.error('Erro ao buscar os serviços do agente:', error);
    throw error;
  }
}

export async function interruptService(id: string, reason: string): Promise<void> {
  try {
    await http.post(`${controller}/interrupt/${id}`, { reason });
  } catch (error) {
    throw new Error('Erro ao interromper o serviço: ' + error);
  }
}

export async function cancelService(id: string, reason: string): Promise<void> {
  try {
    await http.post(`${controller}/cancel/${id}`, { cancelReason: reason });
  } catch (error) {
    throw new Error('Erro ao cancelar o serviço: ' + error);
  }
}

export async function retakeService(id: string, agentId?: string): Promise<void> {
  try {
    const data: { agentId?: string } = {};

    if (agentId) {
      data.agentId = agentId;
    }

    await http.post(`${controller}/retake/${id}`, data);
  } catch (error) {
    throw new Error('Erro ao retomar o serviço: ' + error);
  }
}

