import { AgentRank } from "@/core/model/AgentRank";
import { AnalysisRating } from "@/core/model/AnalysisRating";
import { getByAgent, getAll as getAllRanking, getByDepartment as getByDepartmentRanking, getByCategory as getByCategoryRanking } from "@/core/service/RankingService";
import { getAll as getAllRating, getByActivity, getByDepartment as getByDepartmentRating, getByCategory as getByCategoryRating } from "@/core/service/RatingService";
import { reactive } from "vue";

type FilterParams = {
  page: number;
  limit: number;
  order?: string;
  startDate?: string;
  endDate?: string;
};

const state = reactive<{ analysis: AnalysisRating[] | AgentRank[], total: number }>({
  analysis: [],
  total: 0,
});

const actions = {
  async GetAll(params: FilterParams, analysisType: string) {
    const response = analysisType === 'agent' 
      ? await getAllRanking(params) 
      : await getAllRating(params);

    state.analysis = response.data;
    state.total = response.total || 0;
  },

  async GetByDepartment(id: string, params: FilterParams, analysisType: string) {
    const response = analysisType === 'agent'
      ? await getByDepartmentRanking(id, params)
      : await getByDepartmentRating(id, params);

    state.analysis = response.data;
    state.total = response.total || 0;
  },

  async GetByCategory(id: string, params: FilterParams, analysisType: string) {
    const response = analysisType === 'agent'
      ? await getByCategoryRanking(id, params)
      : await getByCategoryRating(id, params);

    state.analysis = response.data;
    state.total = response.total || 0;
  },

  async GetByActivity(id: string, params: FilterParams) {
    const response = await getByActivity(id, params);
    state.analysis = response.data;
    state.total = response.total || 0;
  },

  async GetByAgent(id: string, params: FilterParams) {
    const response = await getByAgent(id, params);
    state.analysis = response.data;
    state.total = response.total || 0;
  }
};

export { state, actions };
