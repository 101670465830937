import * as JobStore from './modules/job';
import * as BedStore from './modules/beds';
import * as UserStore from './modules/user';
import * as RoleStore from './modules/role';
import * as SelectStore from './modules/select';
import * as Preloader from './modules/preloader';
import * as CompanyStore from './modules/company';
import * as ServiceStore from './modules/service';
import * as ActivityStore from './modules/activity';
import * as CategoryStore from './modules/category';
import * as LocationStore from './modules/location';
import * as OperationStore from './modules/operation';
import * as PreferenceStore from './modules/preference';
import * as DepartmentStore from './modules/department';
import * as Authentication from './modules/authentication';
import * as AnalysisStore from './modules/analysis';


export async function refreshAllData() {
  const promises = [
    DepartmentStore.actions.GetAll(true),
    CategoryStore.actions.GetAll(true),
    ActivityStore.actions.GetAll(true),
    JobStore.actions.GetAll(true),
    BedStore.actions.GetAll(true),
    RoleStore.actions.GetAll(true),
    SelectStore.actions.GetAll(true),
    PreferenceStore.actions.GetAll(true),
    LocationStore.actions.GetAll(),
    CompanyStore.actions.GetAll(),
    OperationStore.actions.GetAll(),
  ];

  await Promise.allSettled(promises);
}

export { 
  Authentication,
  Preloader,
  DepartmentStore,
  CategoryStore,
  ActivityStore,
  ServiceStore,
  JobStore,
  UserStore,
  BedStore,
  RoleStore,
  SelectStore,
  PreferenceStore,
  LocationStore,
  CompanyStore,
  OperationStore,
  AnalysisStore,
};
