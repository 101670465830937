import { http } from '../api';
import { refreshAllData } from '@/store';
import { Filters, PaginateRequest, PaginateResponse } from '../model';
import { CreateLocationRequest, Location } from '../model/Location';

const controller = 'location';

export async function getPaginate(paginate?: PaginateRequest,filters?: Filters): Promise<PaginateResponse<Location>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }

  if (filters?.company) {
    params['company'] = filters?.company;
  }
  const { data } = await http.get<PaginateResponse<Location> | any>(`${controller}?limit=${paginate?.limit ? paginate.limit : 99999}&page=${paginate?.page ? paginate.page : 1}`, {
    params,
  });

    return  { page: data.page, total: data.total ? data.total : data.length , data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };

}

export async function getBy(id: string): Promise<Location> {
  const { data } = await http.get<Location>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateLocationRequest): Promise<Location> {
  const { data } = await http.post<Location>(`${controller}`, payload);
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateLocationRequest): Promise<Location> {
  const { data } = await http.patch<Location>(`${controller}/${id}`, { ...payload, id });
  await refreshAllData();
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}
