export const enum ModalTitles {
    ACTIVITY_UPDATE = "Deseja concluir a edição da atividade ",
    ACTIVITY_DELETE = "Deseja remover a atividade",
    CATEGORY_UPDATE = "Deseja concluir a edição da categoria ",
    CATEGORY_DELETE = "Deseja remover a categoria",
    LOCATION_UPDATE = "Deseja concluir a edição da localização ",
    LOCATION_DELETE = "Deseja remover a localização",
    COMPANY_UPDATE = "Deseja concluir a edição da empresa ",
    COMPANY_DELETE = "Deseja remover a empresa",
    DEPARTMENT_CREATE = "Deseja cadastrar o departamento ",
    DEPARTMENT_UPDATE = "Deseja concluir a edição do departamento ",
    DEPARTMENT_DELETE = "Deseja remover o departamento ",
    JOB_CREATE = "Deseja cadastrar a função ",
    JOB_DELETE = "Deseja remover a função",
    LAYOUT_UPDATE = "As preferências de estilo vão ser atualizadas em todos os aplicativos. ",
    USER_UPDATE = "Deseja concluir a edição do usuário",
    USER_DELETE = "Deseja remover o usuário "
}

export const enum ModalTypes {
    CONFIRME_MODAL = "Confirme Modal",
    YES_MODAL = "Yes Modal",
    ERROR_MODAL = "Error Modal",
    SUCESS_MODAL = "Sucess Modal"
}