import { io, Socket } from 'socket.io-client';

const BASE_URL = process.env.VUE_APP_WEBSOCKET_URL || 'http://localhost:3010/HAC';

let socket: Socket | null = null;

let isUserReconnectedEventRegistered = false;
let isUserReconnectedWithTokenEventRegistered = false;

const getSocket = () => {
  if (socket) return socket;

  socket = io(BASE_URL, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
  });

  setupConnectionHandlers();
  return socket;
};

const setupConnectionHandlers = () => {
  socket!.on('connect', handleConnect);
};

const handleConnect = async () => {
  console.log('Conexão WebSocket estabelecida.');
  await attemptReconnect();
};

const attemptReconnect = async () => {
  let userData: any = localStorage.getItem('usuario');

  let userId: string | null | undefined;
  let userToken: string | null | undefined;

  if (userData) {
    userData = JSON.parse(String(userData));
    userId = userData.id;
    userToken = userData.jwtTokenWebSocket;
  }

  if (!userId) return;
  socket!.emit('reconnect_session', userId);
  if (!isUserReconnectedEventRegistered) {
    socket!.on('user_reconnected', (data: any) => handleUserReconnected(data, userToken));
    isUserReconnectedEventRegistered = true;
  }
};

const handleUserReconnected = (data: any, userToken: any) => {
  if (data) {
    console.log(`Usuário ${data.username} reconectado`);
  } else {
    reconnectWithToken(userToken);
  }
};

const reconnectWithToken = (userToken: any) => {
  socket!.emit('reconnect_session_with_token', userToken);
  if (!isUserReconnectedWithTokenEventRegistered) {
    isUserReconnectedWithTokenEventRegistered = true;
  }
};

export const onDisconnect = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('disconnect', (data: any) => {
    callback(data);
  });
};

export const onReconnect = (socket: Socket, callback: () => void) => {
  socket.on('connect', () => {
    callback();
  });
};

export const handleNewService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('pending_services_list', (data: any) => {
    callback(data);
  });
};

export const handleSlaService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('sla_status_updated_front', (data: any) => {
    callback(data);
  });
};

export const handleCanceledService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('service_canceled', (data: any) => {
    callback(data);
  });
};

export const handleInterruptService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('service_interrupted', (data: any) => {
    callback(data);
  });
};

export const handleRatedService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('service_rated', (data: any) => {
    callback(data);
  });
};

export const handleStartedService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('service_started', (data: any) => {
    callback(data);
  });
};

export const handleFinishedService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('service_finished', (data: any) => {
    callback(data);
  });
};

export const handleAcceptedService = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('service_accepted', (data: any) => {
    callback(data);
  });
};

export const handleLogoutUser = (socket: Socket, callback: (newService: any) => void) => {
  socket.on('online_status_response', (data: any) => {
    callback(data);
  });
};


export const closeConnection = () => {
  if (socket) {
    socket.close();
    socket = null;
    console.log('Socket desconectado');
  }
};

export { getSocket };
