import { http } from "../api";
import { PaginateResponse } from "../model";
import { Role } from "../model/Role";

const controller = '/roles';

export async function getPaginate(): Promise<PaginateResponse<Role>> {
  const { data } = await http.get<PaginateResponse<Role> | any>(controller);

  return  { page: data.page, total: data.total ? data.total : data.length , data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };
}
