import { PaginateRequest, Filters, PaginateResponse, OptionSelect } from "@/core/model";
import { Role } from "@/core/model/Role";
import { getPaginate } from "@/core/service/RoleService";
import { reactive } from "vue";

const state = reactive<{ roles?: Role[], listRolesSelect: OptionSelect[] }>({
    roles: undefined,
    listRolesSelect: []
});

const actions = {
async GetAll(forceRefresh = false) {
    if (!state.roles || forceRefresh) {
        const { data } = await getPaginate();
        state.roles = data;
        state.listRolesSelect = data.map((d) => ({ text: d.displayName, value: d.id }));
    }
},

async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Role>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
},
};

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Role> {
    if (!state.roles) return { data: [], page: 0, total: 0 };
    let data = state.roles;
    if (f?.name) data = data.filter((d) => d.displayName.toLowerCase().includes(f.name ?? ''));

    return { data, page, total: state.roles.length };
}

const getters = {};

export { state, actions, getters };
