<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="progress-svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.0019 21.9983C17.5225 21.9983 21.9979 17.523 21.9979 12.0023C21.9979 6.48171 17.5225 2.00635 12.0019 2.00635C6.48122 2.00635 2.00586 6.48171 2.00586 12.0023C2.00586 17.523 6.48122 21.9983 12.0019 21.9983ZM12.0019 20.3323C16.6024 20.3323 20.3319 16.6029 20.3319 12.0023C20.3319 7.40181 16.6024 3.67235 12.0019 3.67235C7.40133 3.67235 3.67186 7.40181 3.67186 12.0023C3.67186 16.6029 7.40133 20.3323 12.0019 20.3323Z"
            fill="url(#paint0_angular_1946_17135)" />

        <path fill="none" stroke="url(#grad1)" stroke-width="2" stroke-dasharray="62.8319" stroke-dashoffset="0" d="M12 2.0845
            a 9.9155 9.9155 0 0 1 0 19.831
            a 9.9155 9.9155 0 0 1 0 -19.831" stroke-linecap="round" />

        <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stop-color="#FFFFFF" />
                <stop offset="100%" stop-color="#FFFFFF00" />
            </linearGradient>
        </defs>
    </svg>


</template>