<template>
    <svg :width="width" :height="height" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
        <path :d="path" :fill="fillColor" />
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 42
        },
        height: {
            type: Number,
            default: 40
        },
        fillColor: {
            type: String,
            default: 'white'
        },
        iconSize: {
            type: String,
            default: 'large',
        }
    },
    computed: {
        viewBox() {
            return this.iconSize === 'small' ? '0 0 14 14' : '0 0 42 40';
        },
        path() {
            return this.iconSize === 'small'
                ? 'M13.5067 9.61301L9.23998 1.93301C8.66665 0.899675 7.87332 0.333008 6.99998 0.333008C6.12665 0.333008 5.33332 0.899675 4.75998 1.93301L0.493318 9.61301C-0.0466816 10.593 -0.106682 11.533 0.326652 12.273C0.759985 13.013 1.61332 13.4197 2.73332 13.4197H11.2667C12.3867 13.4197 13.24 13.013 13.6733 12.273C14.1067 11.533 14.0467 10.5863 13.5067 9.61301ZM6.49998 4.99967C6.49998 4.72634 6.72665 4.49967 6.99998 4.49967C7.27332 4.49967 7.49998 4.72634 7.49998 4.99967V8.33301C7.49998 8.60634 7.27332 8.83301 6.99998 8.83301C6.72665 8.83301 6.49998 8.60634 6.49998 8.33301V4.99967ZM7.47332 10.8063C7.43998 10.833 7.40665 10.8597 7.37332 10.8863C7.33332 10.913 7.29332 10.933 7.25332 10.9463C7.21332 10.9663 7.17332 10.9797 7.12665 10.9863C7.08665 10.993 7.03998 10.9997 6.99998 10.9997C6.95998 10.9997 6.91332 10.993 6.86665 10.9863C6.82665 10.9797 6.78665 10.9663 6.74665 10.9463C6.70665 10.933 6.66665 10.913 6.62665 10.8863C6.59332 10.8597 6.55998 10.833 6.52665 10.8063C6.40665 10.6797 6.33332 10.5063 6.33332 10.333C6.33332 10.1597 6.40665 9.98634 6.52665 9.85968C6.55998 9.83301 6.59332 9.80634 6.62665 9.77967C6.66665 9.75301 6.70665 9.73301 6.74665 9.71968C6.78665 9.69967 6.82665 9.68634 6.86665 9.67968C6.95332 9.65967 7.04665 9.65967 7.12665 9.67968C7.17332 9.68634 7.21332 9.69967 7.25332 9.71968C7.29332 9.73301 7.33332 9.75301 7.37332 9.77967C7.40665 9.80634 7.43998 9.83301 7.47332 9.85968C7.59332 9.98634 7.66665 10.1597 7.66665 10.333C7.66665 10.5063 7.59332 10.6797 7.47332 10.8063Z'
                : 'M40.5209 27.84L27.7209 4.8C26.0009 1.7 23.6209 0 21.0009 0C18.3809 0 16.0009 1.7 14.2809 4.8L1.48093 27.84C-0.139068 30.78 -0.319068 33.6 0.980932 35.82C2.28093 38.04 4.84093 39.26 8.20093 39.26H33.8009C37.1609 39.26 39.7209 38.04 41.0209 35.82C42.3209 33.6 42.1409 30.76 40.5209 27.84ZM19.5009 14C19.5009 13.18 20.1809 12.5 21.0009 12.5C21.8209 12.5 22.5009 13.18 22.5009 14V24C22.5009 24.82 21.8209 25.5 21.0009 25.5C20.1809 25.5 19.5009 24.82 19.5009 24V14ZM22.4209 31.42C22.3209 31.5 22.2209 31.58 22.1209 31.66C22.0009 31.74 21.8809 31.8 21.7609 31.84C21.6409 31.9 21.5209 31.94 21.3809 31.96C21.2609 31.98 21.1209 32 21.0009 32C20.8809 32 20.7409 31.98 20.6009 31.96C20.4809 31.94 20.3609 31.9 20.2409 31.84C20.1209 31.8 20.0009 31.74 19.8809 31.66C19.7809 31.58 19.6809 31.5 19.5809 31.42C19.2209 31.04 19.0009 30.52 19.0009 30C19.0009 29.48 19.2209 28.96 19.5809 28.58C19.6809 28.5 19.7809 28.42 19.8809 28.34C20.0009 28.26 20.1209 28.2 20.2409 28.16C20.3609 28.1 20.4809 28.06 20.6009 28.04C20.8609 27.98 21.1409 27.98 21.3809 28.04C21.5209 28.06 21.6409 28.1 21.7609 28.16C21.8809 28.2 22.0009 28.26 22.1209 28.34C22.2209 28.42 22.3209 28.5 22.4209 28.58C22.7809 28.96 23.0009 29.48 23.0009 30C23.0009 30.52 22.7809 31.04 22.4209 31.42Z';
        }
    }
}
</script>