import { http } from '../api';
import { Filters, PaginateResponse } from '../model';
import { Activity, Agent, Category, Client, Department, DepartmentWithCategories, DepartmentWithCategoriesAndActivities, Location, Manager } from '../model/SelectData';

const controller = '/select-data';

export async function getAll(filters?: Filters): Promise<PaginateResponse<Client>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Client> | any>(`${controller}/client`, {
    params,
  });

  return { page: data.page, total: data.total ? data.total : data.length, data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };

}

export async function getAllClientWithService(): Promise<Client[]> {
  const response = await http.get(`${controller}/service/client`);
  return response.data;
}

export async function getAllDepartment(): Promise<Department[]> {
  const response = await http.get(`${controller}/department`);
  return response.data[0]["departments"];
}

export async function getAllCategory(departmentId?: string): Promise<DepartmentWithCategories[]> {
  const url = departmentId
    ? `${controller}/category?department=${departmentId}`
    : `${controller}/category`;

  const response = await http.get(url);
  const data = response.data;

  return data.map(department => ({
    id: department.id,
    name: department.name,
    categories: department.categories.map(category => ({
      id: category.id,
      name: category.name
    }))
  }));
}



export async function getAllActivity(departmentId?: string, categoryId?: string): Promise<DepartmentWithCategoriesAndActivities[]> {
  const url = `${controller}/activity`;

  const params = new URLSearchParams();
  if (departmentId) params.append('department', departmentId);
  if (categoryId) params.append('category', categoryId);

  const fullUrl = params.toString() ? `${url}?${params.toString()}` : url;
  const response = await http.get(fullUrl);
  return response.data.map(department => ({
    id: department.id,
    name: department.name,
    categories: department.categories.map(category => ({
      id: category.id,
      title: category.name,
      activities: category.activities.map(activity => ({
        text: activity.name,
        value: activity.id,
      }))
    }))
  }));
}



export async function getAllLocation(): Promise<Location[]> {
  const response = await http.get(`${controller}/location`);
  return response.data;
}

export async function getAllAgent(): Promise<Agent[]> {
  const response = await http.get(`${controller}/agent`);
  return response.data;
}

export async function getAllManager(): Promise<Manager[]> {
  const response = await http.get(`${controller}/manager`);
  return response.data;
}
