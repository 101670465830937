import { refreshAllData } from '@/store';
import { http } from '../api';
import { Filters, PaginateRequest, PaginateResponse } from '../model';
import { CreateUserRequest, User } from '../model/User';

const controller = 'user';

export async function getPaginate(paginate?: PaginateRequest, filters?: Filters): Promise<PaginateResponse<User>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<User> | any>(`${controller}?limit=${paginate?.limit ? paginate.limit : 99999}&page=${paginate?.page ? paginate.page : 1}`);

  return { page: data.page, total: data.total ? data.total : data.length, data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };
}

export async function getPaginateAgents(
  paginate?: PaginateRequest,
  filters?: FiltersAgent
): Promise<PaginateResponse<User>> {
  const params: Record<string, string> = {};

  if (filters?.type && filters.items) {
    params[filters.type] = filters.items.join(',');
  }

  if (filters?.status) {
    params['status'] = filters.status;
  }

  const limit = paginate?.limit || 99999;
  const page = paginate?.page || 1;

  const queryString = new URLSearchParams(params).toString();

  const { data } = await http.get<PaginateResponse<User>>(
    `/${controller}?agents=true&limit=${limit}&page=${page}&${queryString}`
  );

  return { page: data.page, total: data.total, data: data.data, limit: data.limit, totalPages: data.totalPages };
}

export type FiltersAgent = {
  type?: string;
  items?: string[];
  status?: string;
};

export async function getBy(id: string): Promise<User> {
  const { data } = await http.get<User>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateUserRequest): Promise<User> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<User>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateUserRequest): Promise<User> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<User>(`${controller}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

function validateAndAppendAll(formData: FormData, payload: CreateUserRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
  }
}

export async function getCountAgents(filters: FiltersAgent): Promise<{
  total: number;
  waiting: number;
  running: number;
  offline: number;
}> {

  const params: Record<string, string> = {};

  if (filters?.type && filters.items) {
    params[filters.type] = filters.items.join(',');
  }

  const queryString = new URLSearchParams(params).toString();

  const { data } = await http.get(`/${controller}/agent/summary?${queryString}`);

  return data;
}
