import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { User } from '@/core/model/User';
import { getPaginate } from '@/core/service/UserService';
import { reactive } from 'vue';

const state = reactive<{ users?: User[]; listUsersSelect: OptionSelect[] }>({
  users: undefined,
  listUsersSelect: [],
});

const actions = {
  async GetAll(forceRefresh = false, paginate?: PaginateRequest, filters?: Filters): Promise<PaginateResponse<User>  | any> {
    if (!state.users || forceRefresh) {
      const { data, page, total, totalPages, limit } = await getPaginate(paginate, filters);
      state.users = data;
      state.users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      state.listUsersSelect = state.users.map((d) => ({ text: d.name, value: d.id }));
      return { data, page, total, totalPages, limit };
    }

  },

  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<User>> {
    const { data, page, total, totalPages, limit } = await this.GetAll(paginate.force, paginate, filters);
    return { data, page, total, totalPages, limit };
  },
};

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<User> {
  if (!state.users) return { data: [], page: 0, total: 0 };
  let data = state.users;
  if (f?.name) data = data.filter((d) => d.name.toLowerCase().includes(f.name ?? ''));

  const total = data.length;

  const start = (page - 1) * limit;
  const end = start + limit;
  data = data.slice(start, end);

  return { data, page, total };
}

const getters = {};

export { state, actions, getters };
