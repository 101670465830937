<template>
    <svg
        :width="width"
        :height="height"
        :viewBox="viewBox"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path :d="path" :fill="fillColor" />
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 32
        },
        height: {
            type: Number,
            default: 36
        },
        fillColor: {
            type: String,
            default: 'white'
        },
        iconSize: {
            type: String,
            default: 'large',
        }
    },
    computed: {
        viewBox() {
            return this.iconSize === 'small' ? '0 0 20 20' : '0 0 32 36';
        },
        path() {
            return this.iconSize === 'small'
                ? 'M16.3835 8.01444C17.8726 8.87988 17.8726 11.1201 16.3835 11.9856L7.39269 17.2109C5.94548 18.052 4.16699 16.9572 4.16699 15.2253V4.77468C4.16699 3.04276 5.94548 1.94801 7.39268 2.78911L16.3835 8.01444Z'
                : 'M29.3197 13.2346C32.8934 15.3117 32.8934 20.6883 29.3197 22.7653L7.74166 35.3061C4.26837 37.3248 0 34.6973 0 30.5408V5.45922C0 1.30262 4.26837 -1.32476 7.74165 0.693863L29.3197 13.2346Z';
        }
    }
}
</script>
