import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { Company } from '@/core/model/Company';
import { getPaginate } from '@/core/service/CompanyService';
import { reactive } from 'vue';

const state = reactive<{ company?: Company[]; listCompanySelect: OptionSelect[] }>({
  company: undefined,
  listCompanySelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Company> {
  if (!state.company) return { data: [], page: 0, total: 0 };
  let data = state.company;
  if (f?.name) data = data.filter((d) => d.name.toLowerCase().includes(f.name ?? ''));
  const total = data.length;

  const start = (page - 1) * limit;
  const end = start + limit;
  data = data.slice(start, end);
  return { data, page, total };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.company || forceRefresh) {
      const { data } = await getPaginate();
      state.company = data;
    }

    state.listCompanySelect = state.company!
      .map((d) => ({ text: d.name, value: d.id!, clientId: d.clientId}));
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Company>> {
    await this.GetAll(paginate.force);
    return applyFilters(paginate, filters);
  },
};

const getters = {
  async FilterById(companyId: string) {
    if (state.company) {
      const company = state.company.filter((company) => company.id === companyId);
      state.listCompanySelect = company.map((d) => ({ text: d.name, value: d.id!, clientId: d.clientId }));
    }
  }
};

export { state, actions, getters };
