import { OptionSelect, OptionSelectDepartment } from '@/core/model';
import { Client, ListActivitiesGrouped, ListCategoriesGrouped } from '@/core/model/SelectData';
import { getAll, getAllActivity, getAllAgent, getAllCategory, getAllClientWithService, getAllDepartment, getAllLocation, getAllManager } from '@/core/service/SelectService';
import { reactive } from 'vue';

const state = reactive<{
  clients?: Client[];
  listClientsSelect: OptionSelect[];
  listDepartmentsSelect: OptionSelect[];
  listCategoriesSelect: OptionSelect[];
  listCategoriesGrouped: ListCategoriesGrouped[];
  listActivitiesSelect: OptionSelect[];
  listActivitiesGrouped: ListActivitiesGrouped[];
  listLocationsSelect: OptionSelect[];
  listAgentsSelect: OptionSelect[];
  listManagersSelect: OptionSelect[];
}>({
  clients: undefined,
  listClientsSelect: [],
  listDepartmentsSelect: [],
  listCategoriesSelect: [],
  listCategoriesGrouped: [],
  listActivitiesSelect: [],
  listActivitiesGrouped: [],
  listAgentsSelect: [],
  listManagersSelect: [],
  listLocationsSelect: [],
});

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.clients || forceRefresh) {
      const { data } = await getAll();

      state.clients = data;

      state.listClientsSelect = data.map((d) => ({
        text: d.name!,
        value: d.doc!,
      }));

      state.listClientsSelect.sort((a, b) => a.value.localeCompare(b.value));
    }
  },

  async GetAllClientWithService() {
    const data = await getAllClientWithService();

    state.listClientsSelect = data.map((d) => ({
      text: d.name!,
      value: d.doc!,
    }));
  },

  async GetAllDepartment() {
    const data = await getAllDepartment();

    state.listDepartmentsSelect = data.map((d) => ({
      text: d.name!,
      value: d.id!,
      isVisible: false,
      checked: false,
    }));
  },

  async GetAllCategories() {
    const data = await getAllCategory();

    state.listCategoriesSelect = data.flatMap(department =>
      department.categories.map((category) => ({
        text: category.name!,
        value: category.id!,
      }))
    );
  },

  async GetAllCategoriesGroupedByDepartment() {
    const data = await getAllCategory();

    state.listCategoriesGrouped = data.map(department => ({
      title: department.name,
      id: department.id,
      categories: department.categories.map((category) => ({
        text: category.name!,
        value: category.id!,
      }))
    }));
  },

  async GetAllActivity(departmentId?: string, categoryId?: string) {
    const data = await getAllActivity(departmentId, categoryId);
    state.listActivitiesSelect = data.flatMap(department =>
      department.categories.flatMap(category =>
        category.activities.map(activity => ({
          text: activity.text,
          value: activity.value,
        }))
      )
    );
  },

  async GetAllActivitiesGroupedByDepartmentAndCategory(departmentId?: string, categoryId?: string) {
    const data = await getAllActivity(departmentId, categoryId);
    state.listActivitiesGrouped = data.map(department => ({
      title: department.name,
      id: department.id,
      categories: department.categories.map(category => ({
        title: category.title,
        id: category.id,
        activities: category.activities.map(activity => ({
          text: activity.text,
          value: activity.value,
        })),
      })),
    }));
  },

  async GetAllLocation() {
    const data = await getAllLocation();

    state.listLocationsSelect = data.map((d) => ({
      text: d.name!,
      value: d.id!,
    }));
  },

  async GetAllAgent() {
    const data = await getAllAgent();

    state.listAgentsSelect = data.map((d) => ({
      text: d.name!,
      value: d.id!,
    }));
  },

  async getAllManager() {
    const data = await getAllManager();

    state.listManagersSelect = data.map((d) => ({
      text: d.name!,
      value: d.id!,
    }));
  },
};

const getters = {};

export { state, actions, getters };
