import { Filters, OptionSelectActivity, PaginateRequest, PaginateResponse } from '@/core/model';
import { Activity } from '@/core/model/Activity';
import { getPaginate, getByCategory } from '@/core/service/ActivityService';
import { reactive } from 'vue';

const state = reactive<{ activities?: Activity[]; listActivitiesSelect: OptionSelectActivity[];  listNextActivitiesSelect:  OptionSelectActivity[] }>({
  activities: undefined,
  listActivitiesSelect: [],
  listNextActivitiesSelect:[]
});

const actions = {
  async GetAll(forceRefresh = false, paginate?: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Activity> | any> {
    if (!state.activities || forceRefresh) {
      const { data, page, totalPages, limit, total } = await getPaginate(paginate, filters);
      state.activities = data;
      state.listActivitiesSelect = data.map((d) => ({ text: d.name, value: d.id, category: d.category.id }));
      const newdata = data.map((item) => ({
        internalService: item.internalService ? item.internalService : false,
        ...item
      }))
      return {
        data: newdata, page, totalPages, limit, total
      }
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Activity> | any>  {
    return await this.GetAll(paginate.force,paginate, filters);
  },
  async GetByCategory(categoryId: string) {
    const data = await getByCategory(categoryId);
    state.activities = data;
    state.listActivitiesSelect = data.map((d) => ({ text: d.name, value: d.id, category: d.category.id }));
  },

  async GetNextActivitiesByActivityId(activityId: string) {
    const { data } = await getPaginate();
    const activity = data.find((value) => value.id == activityId);
    const nextActivities = data.filter((value) => activity?.nextActivities.includes(value.id));
    state.listNextActivitiesSelect = nextActivities.map((d) => ({ text: d.name, value: d.id, description: d.description, category: d.category.id }));
  },

  async GetNextActivities(nextActivitiesId: Array<string>) {
    const { data } = await getPaginate();
    const nextActivities = data.filter((value) => nextActivitiesId.includes(value.id));
    return nextActivities.map((d) => ({ text: d.name, value: d.id, category: d.category.id }));
  }
};

const getters = {
  async GetByCategory(categoryId: string) {
    if (state.activities) {
      const activities = state.activities.filter((activity) => activity.category.id === categoryId);
      state.listActivitiesSelect = activities.map((d) => ({ text: d.name, value: d.id, category: d.category.id }));
    }
  },
};

export { state, actions, getters };
