import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { Location } from '@/core/model/Location';
import { getPaginate } from '@/core/service/LocationService';
import { reactive } from 'vue';

const state = reactive<{ locations?: Location[]; listLocationsSelect: OptionSelect[] }>({
  locations: undefined,
  listLocationsSelect: [],
});


const actions = {
  async GetAll(forceRefresh = false, paginate?: PaginateRequest, filters?: Filters):  Promise<PaginateResponse<Location> | any>  {
    if (!state.locations || forceRefresh) {
      const { data, page, total, totalPages, limit } = await getPaginate(paginate, filters);
      state.locations = data;
      state.listLocationsSelect = state.locations
      .map((d) => ({ text: d.name, value: d.id, company: d.company.id }));
      return {
        data: data, page, totalPages, limit, total
      }
    }
    
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Location>> {
    return await this.GetAll(paginate.force, paginate, filters);
  },
};

const getters = {
  async FilterByCompany(companyId: string) {
    if (state.locations) {
      const locations = state.locations.filter((location) => location.company.id === companyId);
      state.listLocationsSelect = locations.map((d) => ({ text: d.name, value: d.id, company: d.company.id }));
    }
  }
};

export { state, actions, getters };
