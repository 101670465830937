import { http } from "../api";
import { ApiResponse } from "../interfaces/response";
import { AgentRank } from "../model/AgentRank";

const controller = '/ranking';

type FilterParams = {
  page: number;
  limit: number;
  order?: string;
  startDate?: string;
  endDate?: string
};

function buildQuery(params: FilterParams) {
  const query = Object.keys(params)
    .filter(key => params[key as keyof FilterParams] !== undefined)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key as keyof FilterParams] as string)}`)
    .join('&');
  return query ? `?${query}` : '';
}

export async function getAll(params: FilterParams): Promise<ApiResponse<AgentRank>> {
  const query = buildQuery(params);
  const { data } = await http.get<ApiResponse<AgentRank>>(`${controller}/agents/${query}`);
  return data;
}

export async function getByDepartment(id: string, params: FilterParams): Promise<ApiResponse<AgentRank>> {
  const query = buildQuery(params);
  const { data } = await http.get<ApiResponse<AgentRank>>(`${controller}/agents/department/${id}${query}`);
  return data;
}

export async function getByCategory(id: string, params: FilterParams): Promise<ApiResponse<AgentRank>> {
  const query = buildQuery(params);
  const { data } = await http.get<ApiResponse<AgentRank>>(`${controller}/agents/category/${id}${query}`);
  return data;
}

export async function getByAgent(id: string, params: FilterParams): Promise<ApiResponse<AgentRank>> {
  const query = buildQuery(params);
  const { data } = await http.get<ApiResponse<AgentRank>>(`${controller}/agent/${id}${query}`);
  return data;
}
