<template>

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="#005E59" />
        <path
            d="M14.2969 8.64124L11.9988 10.9393L9.70067 8.64124C9.41075 8.35133 8.92992 8.35133 8.64001 8.64124C8.35009 8.93116 8.35009 9.41199 8.64001 9.7019L10.9381 12L8.64001 14.2981C8.35009 14.588 8.35009 15.0688 8.64001 15.3588C8.92992 15.6487 9.41075 15.6487 9.70067 15.3588L11.9988 13.0607L14.2969 15.3588C14.5868 15.6487 15.0676 15.6487 15.3575 15.3588C15.6474 15.0688 15.6474 14.588 15.3575 14.2981L13.0594 12L15.3575 9.7019C15.6474 9.41199 15.6474 8.93116 15.3575 8.64124C15.0676 8.35133 14.5868 8.35133 14.2969 8.64124Z"
            fill="#005E59" />
    </svg>

</template>