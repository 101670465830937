<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.2857 3.42857C11.2325 3.42857 12 2.66106 12 1.71429C12 0.767512 11.2325 0 10.2857 0C9.33894 0 8.57143 0.767512 8.57143 1.71429C8.57143 2.66106 9.33894 3.42857 10.2857 3.42857Z"
            fill="#B2BBC7" />
        <path
            d="M1.71429 3.42857C2.66106 3.42857 3.42857 2.66106 3.42857 1.71429C3.42857 0.767512 2.66106 0 1.71429 0C0.767512 0 0 0.767512 0 1.71429C0 2.66106 0.767512 3.42857 1.71429 3.42857Z"
            fill="#B2BBC7" />
        <path
            d="M10.2857 12C11.2325 12 12 11.2325 12 10.2857C12 9.33894 11.2325 8.57143 10.2857 8.57143C9.33894 8.57143 8.57143 9.33894 8.57143 10.2857C8.57143 11.2325 9.33894 12 10.2857 12Z"
            fill="#B2BBC7" />
        <path
            d="M1.71429 12C2.66106 12 3.42857 11.2325 3.42857 10.2857C3.42857 9.33894 2.66106 8.57143 1.71429 8.57143C0.767512 8.57143 0 9.33894 0 10.2857C0 11.2325 0.767512 12 1.71429 12Z"
            fill="#B2BBC7" />
    </svg>
</template>