import { reactive, computed } from 'vue';

const state = reactive<{ loading: boolean, active?: boolean }>({
  loading: false,
  active: false
});

const actions = {
  ActionSetPreloader(payload: boolean, active?: boolean) {

    if(active != null || active != undefined) {
      state.active = active;
      state.loading = payload;
    } else if(!state.active){
      state.loading = payload;
    }
  },
};
const getters = {
  isLoading: computed(() => state.loading),
  isActive: computed(() => state.active),
};
export { getters, state, actions };
