import { Filters, OptionSelectOperation, PaginateRequest, PaginateResponse } from '@/core/model';
import { Operation } from '@/core/model/Operation';
import { getPaginate } from '@/core/service/Operation';
import { reactive } from 'vue';

const state = reactive<{ operations?: Operation[]; listOperationsSelect: OptionSelectOperation[],  listUsersOperationsSelect: OptionSelectOperation[] | any }>({
  operations: undefined,
  listOperationsSelect: [],
  listUsersOperationsSelect: [],
});


const actions = {
  async GetAll(forceRefresh = false, paginate?: PaginateRequest, filters?: Filters):  Promise<PaginateResponse<Operation> | any>  {
    if (!state.operations || forceRefresh) {
      const { data, page, total, totalPages, limit } = await getPaginate(paginate, filters);
      state.operations = data;
      state.listOperationsSelect = state.operations
      .map((d) => ({ text: `${d.firstName} ${d.lastName}`, value: d.location!, location: d.location }));
      return {
        data: data, page, totalPages, limit, total
      }
    }
    
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Operation>> {
    return await this.GetAll(paginate.force, paginate, filters);
  },
};

const getters = {
  async GetUsersByRoom(room: string): Promise<void> {
    await actions.GetAll();
    const users = state.operations?.filter((operation) => operation.location == room)
    state.listUsersOperationsSelect = users?.map((u) => ({
      text: `${u.firstName} ${u.lastName}`!,
      value: u.firstName!,
      ...u,
    }))
  }
};

export { state, actions, getters };
